.menu_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

  .menu_div{
    /* max-width: 45rem !important; */
  }

  .footer_category{
    left: -5%;
  }

  .slick_slider_top_reduce{
    top: 24% !important;
  }