.banner_image {
  width: 100%;
  height: 460px;
}

.banner_text_div{
  position: absolute;
  top: 100px;
  width: 600px;
}
.banner_sub_text{

color: gray;
}

/* @media screen and (max-width: 1200px) {
  .banner_image {
      position: absolute;
      top: 8%;
      left: 8%;
      max-width: 290px;
      max-height: 180px;
    }
} */

/* On screens that are 1024px wide or less, the background color is blue */
/* @media screen and (max-width: 1024px) {
  .banner_image {
      position: absolute;
      top: 8%;
      left: 8%;
      max-width: 290px;
      max-height: 180px;
    }
} */
.site_width{
  width: 100%;
}
@media screen and (min-width: 2000px) {
  .banner_image {
      height: 550px;
    }

    .site_width{
      width: 65%;
    }
}

@media screen and (max-width: 1999px) {

    .site_width{
      width: 85%;
    }
}

@media screen and (max-width: 1360px) {

  .site_width{
    width: 100%;
  }
}

/* On screens that are 768px wide or less, the background color is olive */
@media screen and (max-width: 768px) {
  .banner_image {
      max-height: 260px;
    }

  .banner_text{
    font-size: 1.5rem;
      top: -30px !important;

  }
  .banner_text_div{
    top: 50px !important;
}
}

@media screen and (max-width: 480px) {
  .banner_image {
      max-height: 180px;
    }
  .banner_text{
      font-size: small;
      bottom: 25px !important;
  }
  
  /* .banner_text_div{
      top: 50px !important;
  } */
}
@media screen and (max-width: 423px) {
  
  .banner_text_div{
      top: -80px !important;
      left: -30px !important;
  }
}

/* On screens that are 480px wide or less, the background color is olive */
/* @media screen and (max-width: 480px) {
  .banner_image {
    background-color: olive;
    color: white;
  }
} */