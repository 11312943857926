.mobile_bottom_navbar {
  overflow: hidden;
  background-color: rgb(241, 246, 247);
  /* background-color: var(--color-brand); */
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
}

.mobile_cart {
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: auto;
  display: flex;
  position: relative;

}

.mobile_cart_count {
  /* background-color: black; */
  background-color: var(--color-brand);
  color: white;
  font-weight: 700;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  display: flex;
  right: -.75rem;
  top: -.625rem;
  position: absolute;
  min-width: 20px;
  height: 20px;
  padding: 2px;
  /* border-radius: 63px; */
  font-size: 10px;

}

.overlay {
  background-color: rgba(0, 0, 0, .35);
  z-index: 21;
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  opacity: 0;
  top: 0;
  z-index: 9999;
}

.overlay {
  transition: .35s ease-in-out;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
}

.modal_login{
  width: 100%;
  padding: 2rem;
  margin: 2rem;
  border-radius: 0.6rem;
  height: fit-content;
}
.item-center{
  align-items: center;
}

.mobile_cart_new{
  width: 88%;
  right: 0px;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1050;
  overflow: hidden;
  transition: transform .3s;
  /* left: 0; */
  position: fixed;
  z-index: 1050;
  pointer-events: none;
  z-index: 9999;
}

.mobile_cart_content{
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  pointer-events: auto;
}
.mobile_cart_content_nav{
  -webkit-padding-start: 1.25rem;
  padding-inline-start: 1.25rem;
  /* padding-top: .125rem;
  padding-bottom: .125rem; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  /* position: relative;s */
  box-sizing: border-box;
  border-bottom: 1px solid #dddddd;
  /* box-shadow: 5px solid #b9b8b8;; */
  padding: 1.6rem;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9999;
}

.mobile_cart_content_body{
  height: 75%;

}
.mobile_cart_content_footer{
  bottom: 0;
  position: fixed;
  background-color: white;
  width: 88%;
}

.checkout_btn_mobile{
  --tw-text-opacity: 1;
  color: rgb(255 255 255);
  font-size: .875rem;
  line-height: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
  --tw-bg-opacity: 1;
  background-color: #0098b8;
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.p_to_chkout{

}
.p_to_chkout_price{

}

.height_border{
  /* border-inline-start-width: 1px; */
  -webkit-padding-end: 1.25rem;
  padding-inline-end: 0.7rem;
  padding-top: .125rem;
  padding-bottom: .125rem;
  border-color: rgb(255 255 255);
  box-sizing: border-box;
  /* border: 0 solid #f9f9f9; */
  --tw-text-opacity: 1;
  color: rgb(255 255 255);
  font-size: .875rem;
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
  /* background-color: rgb(33 33 33); */
}