.quantity_button_group{
    --tw-border-opacity: 1;
    border-color: rgb(230 230 230);
    border-width: 1px;
    border-radius: .375rem;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    height: 2.75rem;
    display: flex;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    /* background-color: rgb(221, 220, 220); */
}

.quantity_button{
    /* border-inline-end-width: 1px; */
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .3s;
    --tw-border-opacity: 1;
    border-color: rgb(221, 220, 220/var(--tw-border-opacity));
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 2.5rem;
    height: 100%;
    display: flex;
    border: 0 solid #f9f9f9;
    /* background-color: #c8cccc; */
}
.quantity_button:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(3, 137, 163);
    /* background-color: rgb(3, 137, 163); */
}

.quantity_value{
    width: 3rem;
    /* padding: 0; */
    padding-left: 0 !important;
}
   
@media screen and (max-width: 768px) {
    .mobile_cart_btn_group{
        width: 26%;
    }
    .mobile_cart_btn{
        width: 2rem;
    }
  }
  @media screen and (max-width: 426px) {
    .mobile_cart_btn_group{
        width: 46%;
        float: right;
    }
    .mobile_cart_btn{
        width: 1.9rem;
        height: 66%;
        border-radius: 24%;
    }
  }
  @media screen and (max-width: 376px) {
    .mobile_cart_btn_group{
        width: 50.5%;
    }
    .mobile_cart_btn{
        width: 1.9rem;
    }
  }

  @media screen and (max-width: 320px) {
    .mobile_cart_btn_group{
        width: 54.5%;
    }
    .mobile_cart_btn{
        width: 1.9rem;
    }
  }

  .cart_products{
    border-bottom: 1px solid #dddddd;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .mobile_cart_product_name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }